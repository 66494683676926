import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { IkImageComponent } from './ik-image/ik-image.component';
import { IkUploadComponent } from './ik-upload/ik-upload.component';
import { ImageKitConfiguration, ImagekitService } from './imagekit.service';
export class ImagekitioAngularModule {
/**
 * @param {?} config
 * @return {?}
 */
static forRoot(config: ImageKitConfiguration): ModuleWithProviders {
    return {
      ngModule: ImagekitioAngularModule,
      providers: [
        {provide: ImageKitConfiguration, useValue: config }
      ]
    };
  }
static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  declarations: [IkUploadComponent, IkImageComponent],
  imports: [
  ],
  exports: [IkUploadComponent, IkImageComponent],
  providers: [ ImagekitService ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function ImagekitioAngularModule_tsickle_Closure_declarations() {
/** @type {?} */
ImagekitioAngularModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ImagekitioAngularModule.ctorParameters;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
