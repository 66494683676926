import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ImagekitService } from '../imagekit.service';
export class IkUploadComponent implements OnInit {
   fileName:string; //required
   useUniqueFileName:boolean; //optional
   tags:string; //optional
   folder:string; //optional
   isPrivateFile:boolean; //optional
   customCoordinates:string; //optional
   responseFields:string; //optional
   onError: EventEmitter<any> = new EventEmitter();
   onSuccess: EventEmitter<any> = new EventEmitter();
   onFileInput: Function;
  fileToUpload: File = null;
/**
 * @param {?} imagekit
 */
constructor(private imagekit: ImagekitService) { }
/**
 * @return {?}
 */
ngOnInit() {
  }
/**
 * @param {?} e
 * @return {?}
 */
handleFileInput(e) {
    const /** @type {?} */ onError = this.onError;
    const /** @type {?} */ onSuccess = this.onSuccess;
    const /** @type {?} */ files = e.target.files;
    this.fileToUpload = files.item(0);
    if (this.onFileInput) {
      this.onFileInput(e);
      return;
    }
    const /** @type {?} */ params = this.getUploadParams(this.fileToUpload, this.fileName, this.useUniqueFileName, this.tags, this.folder, this.isPrivateFile, this.customCoordinates, this.responseFields)
    const /** @type {?} */ ik = this.imagekit.ikInstance;
    ik.upload(params, function (err, result) {
      if (err) {
        onError.emit(err);
      } else {
        onSuccess.emit(result);
      }
    });
  }
/**
 * @param {?} file
 * @param {?} fileName
 * @param {?=} useUniqueFileName
 * @param {?=} tags
 * @param {?=} folder
 * @param {?=} isPrivateFile
 * @param {?=} customCoordinates
 * @param {?=} responseFields
 * @return {?}
 */
getUploadParams(file, fileName, useUniqueFileName?, tags?, folder?, isPrivateFile?, customCoordinates?, responseFields?) {
    const /** @type {?} */ params:object = {
      file: file,
      fileName: fileName,
    }
    if (useUniqueFileName !== undefined) {
      Object.assign(params, { useUniqueFileName: useUniqueFileName });
    }

    if (folder !== undefined) {
      Object.assign(params, { folder: folder });
    }

    if (isPrivateFile !== undefined) {
      Object.assign(params, { isPrivateFile: isPrivateFile });
    }

    if (tags !== undefined) {
      Object.assign(params, { tags: tags });
    }

    if (customCoordinates !== undefined) {
      Object.assign(params, { customCoordinates: customCoordinates });
    }

    if (responseFields !== undefined) {
      Object.assign(params, { responseFields: responseFields });
    }
    return params;
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'ik-upload',
  template: `<input type="file" (change)="handleFileInput($event)" />`,
  providers: [ImagekitService]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ImagekitService, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'fileName': [{ type: Input, args: ['fileName', ] },],
'useUniqueFileName': [{ type: Input, args: ['useUniqueFileName', ] },],
'tags': [{ type: Input, args: ['tags', ] },],
'folder': [{ type: Input, args: ['folder', ] },],
'isPrivateFile': [{ type: Input, args: ['isPrivateFile', ] },],
'customCoordinates': [{ type: Input, args: ['customCoordinates', ] },],
'responseFields': [{ type: Input, args: ['responseFields', ] },],
'onError': [{ type: Output },],
'onSuccess': [{ type: Output },],
'onFileInput': [{ type: Input },],
};
}

function IkUploadComponent_tsickle_Closure_declarations() {
/** @type {?} */
IkUploadComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
IkUploadComponent.ctorParameters;
/** @type {?} */
IkUploadComponent.propDecorators;
/** @type {?} */
IkUploadComponent.prototype.fileName;
/** @type {?} */
IkUploadComponent.prototype.useUniqueFileName;
/** @type {?} */
IkUploadComponent.prototype.tags;
/** @type {?} */
IkUploadComponent.prototype.folder;
/** @type {?} */
IkUploadComponent.prototype.isPrivateFile;
/** @type {?} */
IkUploadComponent.prototype.customCoordinates;
/** @type {?} */
IkUploadComponent.prototype.responseFields;
/** @type {?} */
IkUploadComponent.prototype.onError;
/** @type {?} */
IkUploadComponent.prototype.onSuccess;
/** @type {?} */
IkUploadComponent.prototype.onFileInput;
/** @type {?} */
IkUploadComponent.prototype.fileToUpload;
/** @type {?} */
IkUploadComponent.prototype.imagekit;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
