import { Component, AfterViewInit, OnInit, ElementRef, Input, OnChanges } from '@angular/core';
import { ImagekitService } from '../imagekit.service';
export class IkImageComponent implements AfterViewInit, OnInit, OnChanges {
   src:string;
   path:string;
   urlEndpoint:string;
   transformation:Array<Object> = [];
   transformationPosition:string;
   queryParameters:Object;
   lqip:any;
  url = '';
  lqipUrl = '';

  observer: MutationObserver;
/**
 * @param {?} el
 * @param {?} imagekit
 */
constructor(private el: ElementRef,
private imagekit: ImagekitService) {
  }
/**
 * @return {?}
 */
ngOnInit(): void {
    this.setUrl(this.src, this.path, this.transformation, this.lqip, this.urlEndpoint, this.transformationPosition, this.queryParameters);
  }
/**
 * @return {?}
 */
ngOnChanges(): void {
    this.ngOnInit();
  }
/**
 * @return {?}
 */
ngAfterViewInit() {
    const /** @type {?} */ that = this;
    this.loadImage(this.lqip && this.lqip.active ? this.lqipUrl : this.url);
    const /** @type {?} */ imageObserver = new IntersectionObserver(function (entry:any, observer) {
      if (entry[0] && entry[0].isIntersecting) {
        let /** @type {?} */ image = entry[0].target;
        that.loadImage(that.url);
        imageObserver.unobserve(image);
      }
    });
    imageObserver.observe(this.el.nativeElement);
  }
/**
 * @param {?=} src
 * @param {?=} path
 * @param {?=} transformation
 * @param {?=} lqip
 * @param {?=} urlEndpoint
 * @param {?=} transformationPosition
 * @param {?=} queryParameters
 * @return {?}
 */
setUrl(src?, path?, transformation?, lqip?, urlEndpoint?, transformationPosition?, queryParameters?) {
    const /** @type {?} */ config = this.getConfigObject(src, path, transformation, transformationPosition, urlEndpoint, queryParameters)
    this.url = this.imagekit.getUrl(config);
    if (lqip && lqip.active === true) {
      this.lqipUrl = this.lqipload(lqip.quality, this.url, this.path);
    }
  }
/**
 * @param {?=} src
 * @param {?=} path
 * @param {?=} transformation
 * @param {?=} transformationPosition
 * @param {?=} urlEndpoint
 * @param {?=} queryParameters
 * @return {?}
 */
getConfigObject(src?, path?, transformation?, transformationPosition?, urlEndpoint?, queryParameters?) {
    const /** @type {?} */ config = {
      transformation: transformation,
    };
    if (urlEndpoint) {
      config['urlEndpoint'] = urlEndpoint;
    }
    if (queryParameters) {
      config['queryParameters'] = queryParameters;
    }
    if (src) {
      config['src'] = src;
      config['transformationPosition'] = 'query';
    } else if (path) {
      config['path'] = path;
      if (transformationPosition) {
        config['transformationPosition'] = transformationPosition;
      }
    } else {
      throw new Error('Missing src / path during initialization!');
    }
    return config;
  }
/**
 * @param {?} url
 * @return {?}
 */
loadImage(url:string) {
    const /** @type {?} */ nativeElement = this.el.nativeElement;
    const /** @type {?} */ attributes = nativeElement.attributes;
    const /** @type {?} */ attrsToSet = this.namedNodeMapToObject(attributes);
    attrsToSet['src'] = url;
    const /** @type {?} */ image = nativeElement.children[0];
    this.setElementAttributes(image, attrsToSet);
  }
/**
 * @param {?} source
 * @return {?}
 */
namedNodeMapToObject(source: NamedNodeMap): any {
    let /** @type {?} */ target = {};
    Object.keys(source).forEach(index => {
      const /** @type {?} */ name = source[index].name;
      const /** @type {?} */ value = source[index].value;
      target[name] = value;
    });
    return target;
  };
/**
 * @param {?} quality
 * @param {?} url
 * @param {?} path
 * @return {?}
 */
lqipload(quality, url, path) {
    let /** @type {?} */ lqip = "";
    if (path) {
      let /** @type {?} */ newUrl = url.split("tr:");
      if (newUrl[0] === url) {
        let /** @type {?} */ temp = url.split("/");
        lqip = `${temp[0]}//${temp[2]}/${temp[3]}/tr:q-${quality}/${temp[4]}`;
      } else {
        lqip = `${newUrl[0]}tr:q-${quality}${newUrl[1]}`;
      }
    } else {
      lqip = `${url}?tr=q-${quality}`;
    }
    return lqip;
  }
/**
 * @param {?} element
 * @param {?} attributesLiteral
 * @return {?}
 */
setElementAttributes(element, attributesLiteral) {
    Object.keys(attributesLiteral).forEach(attrName => {
        element.setAttribute(attrName, attributesLiteral[attrName]);
    });
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'ik-image',
  template: `<img src={{src}}>`,
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: ImagekitService, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'src': [{ type: Input, args: ['src', ] },],
'path': [{ type: Input, args: ['path', ] },],
'urlEndpoint': [{ type: Input, args: ['urlEndpoint', ] },],
'transformation': [{ type: Input, args: ['transformation', ] },],
'transformationPosition': [{ type: Input, args: ['transformationPosition', ] },],
'queryParameters': [{ type: Input, args: ['queryParameters', ] },],
'lqip': [{ type: Input, args: ['lqip', ] },],
};
}

function IkImageComponent_tsickle_Closure_declarations() {
/** @type {?} */
IkImageComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
IkImageComponent.ctorParameters;
/** @type {?} */
IkImageComponent.propDecorators;
/** @type {?} */
IkImageComponent.prototype.src;
/** @type {?} */
IkImageComponent.prototype.path;
/** @type {?} */
IkImageComponent.prototype.urlEndpoint;
/** @type {?} */
IkImageComponent.prototype.transformation;
/** @type {?} */
IkImageComponent.prototype.transformationPosition;
/** @type {?} */
IkImageComponent.prototype.queryParameters;
/** @type {?} */
IkImageComponent.prototype.lqip;
/** @type {?} */
IkImageComponent.prototype.url;
/** @type {?} */
IkImageComponent.prototype.lqipUrl;
/** @type {?} */
IkImageComponent.prototype.observer;
/** @type {?} */
IkImageComponent.prototype.el;
/** @type {?} */
IkImageComponent.prototype.imagekit;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
