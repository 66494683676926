import { Injectable } from '@angular/core';
// import ImageKit from "imagekit-javascript";
const /** @type {?} */ ImageKit = require("imagekit-javascript");
// When SDK is built and packed, the path of this file changes, hence static path cannot work
// const pjson = require("../../package.json");
const /** @type {?} */ SDK_VERSION = '1.0.2';

export interface Lqip {
  readonly active: boolean;
  readonly quality: number;
}
export class ImageKitConfiguration {
  urlEndpoint: string;
  publicKey: string;
  authenticationEndpoint?: string;
  lqip?: Lqip;
  fileName?: string;
  tags?: string;
  useUniqueFileName?: boolean;
  responseFields?: any;
  isPrivateFile?: boolean;
  folder?: string;
  customCoordinates?: any;
  sdkVersion?: string;
}

function ImageKitConfiguration_tsickle_Closure_declarations() {
/** @type {?} */
ImageKitConfiguration.prototype.urlEndpoint;
/** @type {?} */
ImageKitConfiguration.prototype.publicKey;
/** @type {?} */
ImageKitConfiguration.prototype.authenticationEndpoint;
/** @type {?} */
ImageKitConfiguration.prototype.lqip;
/** @type {?} */
ImageKitConfiguration.prototype.fileName;
/** @type {?} */
ImageKitConfiguration.prototype.tags;
/** @type {?} */
ImageKitConfiguration.prototype.useUniqueFileName;
/** @type {?} */
ImageKitConfiguration.prototype.responseFields;
/** @type {?} */
ImageKitConfiguration.prototype.isPrivateFile;
/** @type {?} */
ImageKitConfiguration.prototype.folder;
/** @type {?} */
ImageKitConfiguration.prototype.customCoordinates;
/** @type {?} */
ImageKitConfiguration.prototype.sdkVersion;
}

export class ImagekitService {
  _ikInstance: any;
/**
 * @param {?} configuration
 */
constructor(private configuration: ImageKitConfiguration) {
    (configuration.sdkVersion = `angular-${SDK_VERSION}`),
      (this._ikInstance = new ImageKit(this.configuration));
  }
/**
 * @return {?}
 */
get ikInstance(): any {
    return this._ikInstance;
  }
/**
 * @param {?} config
 * @return {?}
 */
getUrl(config: object): string {
    const /** @type {?} */ url = this._ikInstance.url(config);
    return url;
  }
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ImageKitConfiguration, },
];
}

function ImagekitService_tsickle_Closure_declarations() {
/** @type {?} */
ImagekitService.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ImagekitService.ctorParameters;
/** @type {?} */
ImagekitService.prototype._ikInstance;
/** @type {?} */
ImagekitService.prototype.configuration;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
